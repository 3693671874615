import React, { useEffect, useReducer } from "react";
import ValidationAccountProvider from "../../providers/ValidationAccountProvider";
import ValidationAccountList from "../../components/ValidationAccounts/ValidationAccountList";
import { Switch, Route } from "react-router-dom";
import CreateEditValidationAccount from "../../components/ValidationAccounts/CreateEditValidationAccount";
import { Grid } from "@material-ui/core";
import { useStyles } from "./style";
import { useSelector } from "react-redux";
import UserProvider from "../../providers/UserProvider";
import { USER_TYPES } from "../../constants";
import ValidationService from "../../services/ValidationAccountService";
import apiClient from "../../auth/apiClient";
import { useEnqueueSnackbar } from "../../hooks/useEnqueueSnackbar";
import * as _ from "lodash";
import useThemeContext from "../../hooks/useThemeSwitch";
import { setRootContainerWidth } from "../../reducers/layout/layoutReducer";
import {useCoreEntityContext} from "../../hooks/useCoreEntitySlice";

const initialValidationAccountPageState = {
  formOpen: false,
  validationAccountID: undefined
};
function validationAccountPageReducer(state, { type, payload }) {
  switch (type) {
    case "EDIT_VALIDATION_ACCOUNT":
      return {
        ...state,
        validationAccountID: payload.validationAccountID,
        formOpen: true
      };
    case "CLOSE_FORM":
      return { ...state, validationAccountID: undefined, formOpen: false };
    case "ADD_VALIDATION_ACCOUNT":
      return { ...state, validationAccountID: undefined, formOpen: true };
    case "UPDATE_VALIDATION_ACCOUNT":
      return { ...state, validationAccountID: payload.validationAccountID };
    default:
      return state;
  }
}
const validationService = new ValidationService(apiClient);

const ValidationAccountsPage = () => {
  const classes = useStyles();
  const useCoreEntitySlice = useCoreEntityContext();

  const facilityID = useSelector(state => useCoreEntitySlice ? state.coreEntities?.ContextID : state.entities?.ContextID);
  const scopeAwareFacilityID = useSelector((state) => state.entityScope?.facilityGroupId || facilityID);
  const userType = useSelector(state => state.user?.UserType);
  const userID = useSelector(state => state.user?.UserID);
  const enqueueSnackbar = useEnqueueSnackbar();
  const { layoutReducer } = useThemeContext();
  const [layoutState, layoutDispatch] = layoutReducer;
  const [state, dispatch] = useReducer(
    validationAccountPageReducer,
    initialValidationAccountPageState
  );

  useEffect(() => {
    layoutDispatch({ type: setRootContainerWidth, payload: false });
  }, [layoutDispatch]);

  useEffect(() => {
    if (userType == USER_TYPES.ValidationAccount) {
      // if we have access to this page, then we are a validation account holder
      getValidationAccountHolder(userID, scopeAwareFacilityID).then(contractHolderID => {
        if (!_.isUndefined(contractHolderID)) {
          dispatch({
            type: "EDIT_VALIDATION_ACCOUNT",
            payload: { validationAccountID: contractHolderID }
          });
        }
      });
    }
  }, [userType, userID, scopeAwareFacilityID]);

  const getValidationAccountHolder = async (userID, entityIDparam) => {
    try {
      const response = await validationService.getValidationAccountHolderByUserID(
        userID,
        entityIDparam
      );
      return response.data?.[0]?.contractHolderID;
    } catch (err) {
      console.error(err);
      enqueueSnackbar("Unable to retrieve validation account holder info", {
        variant: "error",
        tag: "FailedToRetrieveValidationAccountholder"
      });
    }
  };

  const handleAdd = () => {
    dispatch({ type: "ADD_VALIDATION_ACCOUNT" });
  };

  const handleSelectValidationAccount = validationAccountID => {
    dispatch({
      type: "EDIT_VALIDATION_ACCOUNT",
      payload: { validationAccountID }
    });
  };

  const handleDelete = () => {
    dispatch({ type: "CLOSE_FORM" });
  };

  const showAccountList = () => {
    return (
      userType != USER_TYPES.ValidationAccount &&
      layoutState.rootWidth == false &&
      state.formOpen === false
    );
  };

  const showValidationAccountForm = () => {
    return state.formOpen === true;
  };

  const handleCancel = () => {
    dispatch({ type: "CLOSE_FORM" });
  };

  const handleChange = account => {
    dispatch({
      type: "UPDATE_VALIDATION_ACCOUNT",
      payload: { validationAccountID: account.contractHolderID }
    });
  };

  return (
    <div className={classes.root}>
      <UserProvider>
        <ValidationAccountProvider>
          <Grid container className={classes.root}>
            <Grid className={classes.contentContainer} item xs={12} lg={12}>
              <Switch>
                <Route exact path="/validationaccounts">
                  {/* Waiting for layout root width here to ensure that the table css gets rendered properly */}
                  {showAccountList() ? (
                    <ValidationAccountList
                      onAddClick={handleAdd}
                      onSelect={handleSelectValidationAccount}
                    />
                  ) : (
                    showValidationAccountForm() && (
                      <CreateEditValidationAccount
                        contractHolderID={state.validationAccountID}
                        onDelete={handleDelete}
                        facilityID={scopeAwareFacilityID}
                        onChange={handleChange}
                        onGoBackClick={handleCancel}
                      />
                    )
                  )}
                </Route>
              </Switch>
            </Grid>
          </Grid>
        </ValidationAccountProvider>
      </UserProvider>
    </div>
  );
};

export default ValidationAccountsPage;
