export const onFiltersChanged = "ON_FILTERS_CHANGED"
export const onIsDayUseAssigned = 'IS_DAY_USE_ASSIGNED'

export default function accessGroupReducer(state, { payload, type }) {
  switch (type) {
    case onFiltersChanged:
      return { ...state, ...payload };
    case onIsDayUseAssigned:
      return { ...state, ...payload };
    default:
      return state;
  }
}